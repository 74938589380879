<template>
  <div class="grid">
    <div class="col-12">
      <div class="font-medium text-3xl text-900 mb-3">
        <span class="text-primary text-5xl">/</span> {{ title }}
      </div>
      <div class="text-500 mb-5">Preencha os campos abaixo</div>

      <div class="card p-fluid w-full" style="max-width: 550px">
        <h5 class="mb-4">Novo usuário</h5>

        <Toast />

        <div class="">
          <div class="field">
            <label for="name">Nome</label>
            <InputText
              id="name"
              v-model.trim="form.name"
              required="true"
              autofocus
              autocomplete="off"
              :class="{ 'p-invalid': submitted && !form.name }"
            />
            <small class="p-error" v-if="submitted && !form.name"
              >Nome é obrigatório.</small
            >
          </div>

          <div class="field">
            <label for="email">E-mail</label>
            <InputText
              id="email"
              v-model.trim="form.email"
              required="true"
              autocomplete="off"
              :class="{ 'p-invalid': submitted && !form.email }"
            />
            <small class="p-error" v-if="submitted && !form.email"
              >E-mail é obrigatório.</small
            >
          </div>

          <div class="field w-6">
            <label for="password">Senha</label>
            <InputText
              type="password"
              id="password"
              v-model.trim="form.password"
              required="true"
              autocomplete="new-password"
              :class="{ 'p-invalid': submitted && !form.password }"
            />
            <small class="p-error" v-if="submitted && !form.password"
              >E-mail é obrigatório.</small
            >
          </div>

          <div class="field">
            <label for="role">Perfil</label>
            <Dropdown
              id="role"
              v-model="form.role"
              :options="roles"
              optionLabel="label"
              optionValue="value"
              placeholder="Escolha um perfil"
            ></Dropdown>
          </div>

          <div class="field">
            <label for="client">Cliente</label>
            <Dropdown
              id="client"
              v-model="form.client"
              :options="clients"
              optionLabel="label"
              placeholder="Sem cliente específico"
            ></Dropdown>
          </div>
        </div>

        <div class="flex mt-5">
          <Button
            label="Salvar"
            icon="pi pi-check"
            class="p-button-primary w-6"
            @click="save"
          />
          <Button
            label="Voltar"
            icon="pi pi-times"
            class="p-button-text w-3 ml-auto"
            @click="$router.push('/usuarios')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CrudService from '@/services/crud'

export default {
  data() {
    return {
      form: {},
      submitted: false,
      roles: [
        { label: 'Administrador', value: 'ADMIN' },
        { label: 'Usuário', value: 'USER' }
      ],
      clients: [{ label: 'Recovery', value: '001' }]
    }
  },
  async mounted() {
    this.$service = new CrudService('/admin/users')
    if (this.$route.params.id) {
      const { data } = await this.$service.findById(this.$route.params.id)

      this.form = data
      console.log(this.form)
      let cliente = data.cliente
      this.form.idCliente = cliente != null ? cliente.id : null
    }
  },
  computed: {
    title() {
      return this.$route.params.id ? 'Editar usuário' : 'Adicionar usuário'
    }
  },
  methods: {
    async save() {
      this.submitted = true
      try {
        if (!this.$route.params.id) {
          this.form.passwordConfirmation = this.form.password
        }
        console.log(this.form)
        await this.$service.save(this.form)
        this.$toast.add({
          severity: 'success',
          summary: 'Usuário gravado com sucesso!',
          life: 3000
        })
        this.$router.replace('list')
      } catch (err) {
        this.$toast.add({
          severity: 'error',
          summary: 'Problemas ao armazenar o usuário!',
          life: 3000
        })
        console.log(err)
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
